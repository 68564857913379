import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f6fa3b9 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _6a59bc9a = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _ab1aad3a = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _6ffcbdea = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _f8a1b4ce = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _1a40fc69 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _44aeb908 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _36999cdc = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _7dc6806a = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _28dc426a = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _96b6f1b2 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _216cfd9e = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _04db73dc = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _32d3a6f6 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _064e03fa = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _30b6c0ce = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _15061ada = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _7dc59360 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _3f247da4 = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _296ef0f9 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _9a9902b2 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _75fbe708 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _2e6bbb9d = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _70c587c6 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _6c99e8d2 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _0268fe5c = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _36775ed7 = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _2cc0a8cc = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _74b08ad3 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _7c5bbe71 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _2b4ffdc6 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _67b4b530 = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _d1ef756e = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _32b0a382 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _57d38bca = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _63c95c9e = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _ac8c645c = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _4715c2b0 = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _3e1e9006 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _33591277 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _0a7c7200 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _22fac1a7 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _e1768714 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _269bd37f = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _290c73a0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _7f6fa3b9,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _6a59bc9a,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _ab1aad3a,
    name: "help"
  }, {
    path: "/verify-email",
    component: _6ffcbdea,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _f8a1b4ce,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _1a40fc69,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _44aeb908,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _36999cdc,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _7dc6806a,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _28dc426a,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _96b6f1b2,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _216cfd9e,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _04db73dc,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _32d3a6f6,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _064e03fa,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _30b6c0ce,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _15061ada,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _7dc59360,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _3f247da4,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _296ef0f9,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _9a9902b2,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _75fbe708,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _2e6bbb9d,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/fund-selection",
    component: _70c587c6,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _6c99e8d2,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _0268fe5c,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _36775ed7,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _2cc0a8cc,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _74b08ad3,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _7c5bbe71,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _2b4ffdc6,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _67b4b530,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _d1ef756e,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _32b0a382,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _57d38bca,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _63c95c9e,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _ac8c645c,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _4715c2b0,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _3e1e9006,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _33591277,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _0a7c7200,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _22fac1a7,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _e1768714,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _269bd37f,
    name: "blog-slug"
  }, {
    path: "/",
    component: _290c73a0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
